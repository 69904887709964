<template>
  <div class="container">
      <div class="row fade-in-fast">
        <div class="col-sm-6">
          <div v-html="p1"></div>
          <div v-html="p2"></div>
        </div>
        <div class="col-sm-6">
          <br>          
          <br>          
          <br>          
          <br>          
          <img src="https://tilbud.metz.dk/root/0/D7A0652EA9B5D9DAC1257F8800496328/Body/0.7A8?OpenElement&FieldElemFormat=gif" width="500" height="269" class="img-fluid">
        </div>        
      </div>
      <div class="row fade-in-fast mt-4">
      </div>
      <!--div class="row fade-in-fast mt-4">
        <div class="col-sm-6">
          <div v-html="p3"></div>
        </div>

        <div class="col-sm-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2240.425349035238!2d12.529940951165095!3d55.83793299279557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652494a2e471de5%3A0x8c64d04f56027ce4!2sSkelstedet%209%2C%202950%20Vedb%C3%A6k!5e0!3m2!1sda!2sdk!4v1612426532343!5m2!1sda!2sdk" width="600" height="450" frameborder="0" style="border:0;filter:grayscale(50%);" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>     
      </!div-->

  </div>
</template>

<script>
import translations from '@/plugins/translations.js';
import utils from '@/plugins/utils';
export default {
  data: function() {
    return {
      'p1': translations[window.APP_API_LANG]['pages']['about-p1'],
      'p2': translations[window.APP_API_LANG]['pages']['about-p2'],
      'p3': translations[window.APP_API_LANG]['pages']['about-p3']
    }
  },
  created() {
    this.lang = window.APP_API_LANG;
    this.translations = translations;
  },  
  mounted() {
    this.setTitle();
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,

  }
};
</script>

<style>
</style>