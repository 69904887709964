<template>
  <div>
    <product-list :products="products" />
  </div>
</template>

<script>
import ProductList from "@/components/ProductList";
import translations from '@/plugins/translations.js';
import utils from '@/plugins/utils';
export default {
  props: ['products'],
  components: {
    ProductList
  },
  created() {
    this.lang = window.APP_API_LANG;
    this.translations = translations;
  },  
  mounted() {
    this.setTitle()
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,

  }
};
</script>

<style>

</style>