<template>
  <div>
    <nav class="bg-light menu-2 hide-on-mobile" style="height:30px">
      <div class="container text-right">
        <ul class="navbar-nav mr-auto float-end">
          <li class="nav-item">
            <a :href="$router.resolve({name: 'csr', query: {'lang': this.lang, 'cur': this.cur}}).href" class="nav-link c1">{{t(this.lang, 'csr')}}</a>
          </li>
          <li class="nav-item">
            <a :href="$router.resolve({name: 'about', query: {'lang': this.lang, 'cur': this.cur}}).href" class="nav-link c1">{{t(this.lang, 'about')}}</a>
          </li>
          <li v-if="checkLanguages(products.languages)" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle c1" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img :src="`../../img/flags/${this.lang}.svg`" width="12" class="flag-icon"> {{this.lang|upper}}</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- 
              <a v-if="$route.params.id" :href="$router.resolve({name: 'product', query: {'lang': 'da', 'cur': this.cur}}).href" class="dropdown-item" :class="{'d-none': this.lang == 'da'}">🇩🇰 Dansk</a>
              <a v-else :href="$router.resolve({name: 'home', query: {'lang': 'da', 'cur': this.cur}}).href" class="dropdown-item" :class="{'d-none': this.lang == 'da'}">🇩🇰 Dansk</a>
              -->
              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', 'da', cur)" class="dropdown-item" :class="{'d-none': this.lang == 'da'}"><img :src="`../../img/flags/da.svg`" width="12" class="flag-icon"> DA - Dansk</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', 'da', cur)" class="dropdown-item c1" :class="{'d-none': this.lang == 'da'}"><img :src="`../../img/flags/da.svg`" width="12" class="flag-icon"> DA - Dansk</a>
              
              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', 'en', cur)" class="dropdown-item" :class="{'d-none': this.lang == 'en'}"><img :src="`../../img/flags/en.svg`" width="12" class="flag-icon"> EN - English</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', 'en', cur)" class="dropdown-item c1" :class="{'d-none': this.lang == 'en'}"><img :src="`../../img/flags/en.svg`" width="12" class="flag-icon"> EN - English</a>
            </div>
          </li>        
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle c1" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img :src="`../../img/flags/${this.cur}.svg`" width="12" class="flag-icon"> {{this.cur|upper}}</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', lang, 'dkk')" class="dropdown-item" :class="{'d-none': this.cur == 'dkk'}"><img :src="`../../img/flags/dkk.svg`" width="12" class="flag-icon"> DKK - {{t(this.lang, 'danish-crone')}}</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', lang, 'dkk')" class="dropdown-item c1" :class="{'d-none': this.cur == 'dkk'}"><img :src="`../../img/flags/dkk.svg`" width="12" class="flag-icon"> DKK - {{t(this.lang, 'danish-crone')}}</a>
              
              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', lang, 'eur')" class="dropdown-item" :class="{'d-none': this.cur == 'eur'}"><img :src="`../../img/flags/eur.svg`" width="12" class="flag-icon"> EUR - Euro</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', lang, 'eur')" class="dropdown-item c1" :class="{'d-none': this.cur == 'eur'}"><img :src="`../../img/flags/eur.svg`" width="12" class="flag-icon"> EUR - Euro</a>
            </div>
          </li>          
        </ul>
      </div>
    </nav>
    
    <nav class="menu-1 navbar navbar-light justify-content-center pb-0 mt-2">
      <a :href="$router.resolve({name: 'home', query: {'lang': this.lang, 'cur': this.cur}}).href" class="c1"><img src="/img/baxx-metz-logo.png" width="" height="22" title="BAXX & METZ logo"></a>
    </nav>

    <nav v-if="Object.keys(products).length > 0" class="container menu-1 navbar navbar-expand-lg navbar-light pt-0 pb-0">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div :style="Object.keys(products).length == 0 ? 'display:none!important' : null"  class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto" style="margin-left:-8px">
          <li class="nav-item">
            <a :href="$router.resolve({name: 'home', query: {'lang': this.lang, 'cur': this.cur}}).href" class="nav-link c1">{{t(this.lang, 'frontpage')}}</a>
          </li>
          <li class="nav-item">
            <a :href="$router.resolve({name: 'overview', query: {'lang': this.lang, 'cur': this.cur}, props: ['products']}).href" class="nav-link c1">{{t(this.lang, 'products')}}</a>
          </li>
          <li class="nav-item">
            <a :href="$router.resolve({name: 'contact', query: {'lang': this.lang, 'cur': this.cur}}).href" class="nav-link c1">{{t(this.lang, 'contact')}}</a>
          </li>
          <li class="nav-item">
            <a :href="$router.resolve({name: 'offer', query: {'lang': this.lang, 'cur': this.cur}}).href" class="nav-link c1">{{t(this.lang, 'offer')}}</a>
          </li>
          <li class="nav-item hide-on-desktop">
            <a :href="$router.resolve({name: 'csr', query: {'lang': this.lang, 'cur': this.cur}}).href" class="nav-link c1">{{t(this.lang, 'csr')}}</a>
          </li>
          <li class="nav-item hide-on-desktop">
            <a :href="$router.resolve({name: 'about', query: {'lang': this.lang, 'cur': this.cur}}).href" class="nav-link c1">{{t(this.lang, 'about')}}</a>
          </li>
          <li class="nav-item dropdown hide-on-desktop">
            <a class="nav-link dropdown-toggle c1" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img :src="`../../img/flags/${this.lang}.svg`" width="12" class="flag-icon"> {{this.lang|upper}}</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- 
              <a v-if="$route.params.id" :href="$router.resolve({name: 'product', query: {'lang': 'da', 'cur': this.cur}}).href" class="dropdown-item" :class="{'d-none': this.lang == 'da'}">🇩🇰 Dansk</a>
              <a v-else :href="$router.resolve({name: 'home', query: {'lang': 'da', 'cur': this.cur}}).href" class="dropdown-item" :class="{'d-none': this.lang == 'da'}">🇩🇰 Dansk</a>
              -->

              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', 'da', cur)" class="dropdown-item" :class="{'d-none': this.lang == 'da'}"><img :src="`../../img/flags/da.svg`" width="12" class="flag-icon"> DA - Dansk</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', 'da', cur)" class="dropdown-item c1" :class="{'d-none': this.lang == 'da'}"><img :src="`../../img/flags/da.svg`" width="12" class="flag-icon"> DA - Dansk</a>
              
              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', 'en', cur)" class="dropdown-item" :class="{'d-none': this.lang == 'en'}"><img :src="`../../img/flags/en.svg`" width="12" class="flag-icon"> EN - English</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', 'en', cur)" class="dropdown-item c1" :class="{'d-none': this.lang == 'en'}"><img :src="`../../img/flags/en.svg`" width="12" class="flag-icon"> EN - English</a>
            </div>
          </li>        
          <li class="nav-item dropdown hide-on-desktop">
            <a class="nav-link dropdown-toggle c1" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img :src="`../../img/flags/${this.cur}.svg`" width="12" class="flag-icon"> {{this.cur|upper}}</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', lang, 'dkk')" class="dropdown-item" :class="{'d-none': this.cur == 'dkk'}"><img :src="`../../img/flags/dkk.svg`" width="12" class="flag-icon"> DKK - {{t(this.lang, 'danish-crone')}}</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', lang, 'dkk')" class="dropdown-item c1" :class="{'d-none': this.cur == 'dkk'}"><img :src="`../../img/flags/dkk.svg`" width="12" class="flag-icon"> DKK - {{t(this.lang, 'danish-crone')}}</a>
              
              <a v-if="$route.params.id" href="" v-on:click="refreshLocalStorage('product', lang, 'eur')" class="dropdown-item" :class="{'d-none': this.cur == 'eur'}"><img :src="`../../img/flags/eur.svg`" width="12" class="flag-icon"> EUR - Euro</a>
              <a v-else href="" v-on:click="refreshLocalStorage('home', lang, 'eur')" class="dropdown-item c1" :class="{'d-none': this.cur == 'eur'}"><img :src="`../../img/flags/eur.svg`" width="12" class="flag-icon"> EUR - Euro</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import translations from '../plugins/translations';
import utils from '@/plugins/utils'
export default {
  props: ['products'],
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    upper: function (value) {
      if (!value) return '' 
      value = value.toString()
      return value.toUpperCase()
    }
  },  
  computed: {
    // ...mapState("product", ["products"])
  },
  created() {
    this.customer_id = window.APP_API_CUSTOMER;
    this.lang = window.APP_API_LANG;
    this.cur = window.APP_API_CUR;
    this.translations = translations;
  },
  mounted() {
    // this.getProducts()
  },
  methods: {
    t: utils.t,
    showLang: utils.showLang,
    showCur: utils.showCur,

    checkLanguages(lang) {
      if (typeof lang !== 'undefined') {
        if (lang.length > 1) {
          return true;
        }
      }
      return false;
    },

    // ...mapActions("product", ["getProducts"]),

    allStorageKeys() {
      var keys = Object.keys(localStorage);
      return keys;
    },

    // Refresh local storage on language change
    refreshLocalStorage(goto, lang, cur) {
      // Remove all locally stored products from other offers.
      const localStorageKeys = this.allStorageKeys();
      const _keys = localStorage.length;
      let i = 0;
      for (i = 0; i < _keys; i++) {
          if (localStorageKeys[i].includes(window.APP_API_CUSTOMER)) {
              localStorage.removeItem(localStorageKeys[i]);
          }
      }
      // Update router.
      this.$router.push({name: goto, query: {'lang': lang, 'cur': cur}});
      // location.reload();
    }
  }
};
</script>

<style>
.menu-2 .navbar-nav,
.menu-2 .navbar-nav .nav-item {
  display: inline-block;
}
.menu-2 .navbar-nav .nav-item {
  margin-right: 1rem;
}

.menu-2 .navbar-nav .nav-item .nav-link {
  padding-top: .3rem;
  font-size: 13px;
}
.flag-icon {
  margin-top:-3px;
}
.menu-1 {
  clear:both;
}
</style>