<template>
  <div class="fade-in-fast no-offer" style="display:none" id="error-msg" v-cloak>
    <div class="mx-auto _text-light _text-center _err-msg _bg-error">
      <h1 v-html="t(getLang(), 'pageNotFoundHeader')"></h1>
    </div>
    <p v-html="t(getLang(), 'pageNotFoundBody')" class="fade-in-slow"></p>
    <br><br>
  </div>
</template>

<script>
import translations from '../plugins/translations';
import utils from '@/plugins/utils'
export default {
  created() {
    this.lang = window.APP_API_LANG;
    this.translations = translations;
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t
  }
};
</script>

<style>

</style>