<template>
  <div :class="products.theme" class="home">
    {{getSetTitleFromWindow()}}

    <div v-if="noOffer"></div>

    <div v-if="loading" class="loading-spinner"><div></div><div></div><div></div><div></div></div>
    <div v-else class="fade-in-fast"></div> 
    <!-- end loading else -->

    <!-- Header text -->
    <div v-if="Object.keys(products).length > 0" class="bg1" :class="{'c2' : products.theme == 'xmas', 'c2' : products.theme == 'merchandise'}" v-cloak>
      <div class="container">

        <div class="row mx-auto pt-3 pb-3">
          <div class="col-sm-6 mt-3 pl-0 fs2">
            <p class="fade-in-slower" v-html="products.frontpageText"></p>
          </div>
          <div class="col-sm-6 d-flex align-items-top">
            <h1 v-if="products.theme != 'xmas' || products.theme != 'xmas-xtra'" class="w100 welcome-text" style="text-align:right">{{t(getLang(), 'welcome-to')}}<br>BAXX & METZ<br>{{products.title}}</h1>
            <h1 v-else class="w100" style="text-align:right">{{t(getLang(), 'xmas')}} </h1>
          </div>
        </div> <!-- end if product -->
      
      </div>
    </div>

    <div class="container overview">

      <no-offer />

      <div v-if="Object.keys(products).length > 0" class="row mx-auto pb-3">
        <h3 class="mt-4 pl-0">{{t(getLang(), 'products')}}</h3>
      </div>

      <div class="row other-products fade-in-slow">
        <thumbnails :products="products" />
      </div>
      <br><br>
    </div>

  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import Thumbnails from "@/components/Thumbnails";
import NoOffer from "@/components/NoOffer";
import translations from '@/plugins/translations';
import utils from '@/plugins/utils';
import $ from 'jquery';
export default {
  props: ['products'],
  data: function() {
    return {
      noOffer: false
    }
  },
  components: {
    Thumbnails,
    NoOffer
  },
  computed: {
    // ...mapState("product", ["products"])
  },
  created() {
    this.loading = true;
    let urlParams = new URLSearchParams(window.location.search);
    this.lang = (urlParams.get('lang') == null) ? window.APP_API_LANG : urlParams.get('lang');
    this.cur = (urlParams.get('cur') == null) ? window.APP_API_CUR : urlParams.get('cur');
    this.translations = translations;
  },  
  mounted() {
    // this.getProducts(),
    this.setTitle();
    this.$nextTick(() => {
      // this.noOffer = window.APP_NO_OFFER;
      // this.loading = false;
      this.checkOffer();
      this.initLoading();
    })
  },
  mutations: {
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
    // Set meta title
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,

    getSetTitleFromWindow() {
      const metaTitle = document.querySelector('head title');
      if (typeof window.APP_TITLE != 'undefined') {
        metaTitle.textContent = window.APP_TITLE + ' - BAXX & METZ';
      }
      else {
        metaTitle.textContent = this.getTitle();
      }
    },

    checkOffer() {
      this.noOffer = window.APP_NO_OFFER;
    },

    initLoading() {
      let endCheck = true;
      (async() => {
        while(!window.PRODUCTS_LOADED && endCheck) {
          // await new Promise(resolve => setTimeout(resolve, 100));
          await new Promise(function (resolve) {
            let counter = setTimeout(resolve, 10);
            if (window.APP_NO_OFFER) endCheck = false;
            if (counter > 420) endCheck = false;
          });
        }
        $('.loading-spinner').remove();
        if (typeof window.APP_ERROR != 'undefined') {
          $('#error-msg').css('display', 'block');
        }
        if (window.APP_ERROR) console.log(window.APP_ERROR);
      })();
    },

    getSlicedArray(array, n) {
      return array.slice(0, n);
    }

    // ...mapActions("product", ["getProducts"])
  }
};
</script>

<style>
.xmas-xtra h1,
.xmas h1 {
  font-family: 'Abril Fatface'!important;
}

.welcome-text {
  font-size: 2rem;
}

.home.xmas .container,
.home.xmas-xtra .container {
  background-color: #7b1f1a;
}
.home.xmas .container.overview,
.home.xmas-xtra .container.overview {
  background-color: transparent;
}

.home.xmas,
.home.xmas h3,
.home.xmas-xtra,
.home.xmas-xtra h3,
.c2 .container {
  color: #fff;
}

.c2 .container {
  background-color: #062a39;
}

.lazy {
  background-image: none;
  background-color: rgba(242, 242, 242, .5);
}

@keyframes color {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #dc3545;
  }
}

@media (max-width: 768px) {
  .row.other-products {
    margin: 0;
  }
  h3 {
    margin-left: 15px;
  }
}
[v-cloak] {
  display: none;
}
</style>