<template>
  <div :class="products.theme" class="productlist"> 
    <div class="container">

      <no-offer />

      <div class="product-container">

        <ul v-if="products.products" id="navtest" class="nav mb-3">
          <li v-if="getFilteredCategory(products.products)" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{t(getLang(),'categories')}} <span class="caret"></span></a>
            <ul aria-labelledby="filter-sub" class="dropdown-menu" id="filter-drop-down_">
              <a class="dropdown-item" @click="filterThisClick('alle')" href="#">{{t(getLang(), 'all')}}</a>
              <a v-for="(category, index) in getFilteredCategory(products.products)" :key="index" :href="'#' + category" class="dropdown-item" @click="filterThisClick(category)">{{category}}</a>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">BRANDS <span class="caret"></span></a>
            <ul aria-labelledby="filter-sub" class="dropdown-menu" id="filter-drop-down_">
              <a class="dropdown-item" @click="filterThisClick('alle')" href="#">{{t(getLang(), 'all')}}</a>
              <a v-for="(supplier, index) in getFilteredBrand(products.products)" :key="index" :href="'#' + supplier" class="dropdown-item" @click="filterThisClick(supplier)">{{supplier}}</a>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{t(getLang(), 'sort')}} <span class="caret"></span></a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" @click="sortThisClick('title')" href="#">{{t(this.lang, 'a-z')}}</a>
              <a class="dropdown-item" @click="sortThisClick('titleReversed')" href="#">{{t(this.lang, 'z-a')}}</a>
              <a class="dropdown-item" @click="sortThisClick('price')" href="#">{{t(this.lang, 'price-high-low')}}</a>
              <a class="dropdown-item" @click="sortThisClick('priceReversed')" href="#">{{t(this.lang, 'price-low-high')}}</a>
            </div>
          </li>
          <li class="search-bar-container">
            <input style="margin-left: 20px; height: 40px;box-shadow: 0 8px 6px -6px #eee;" class="form-control" :placeholder="t(this.lang, 'search')" type="search" id="filters-search-input">
          </li>
        </ul>

        <div v-if="loading" class="loading-spinner"><div></div><div></div><div></div><div></div></div>
        <div v-else class="fade-in-fast">
          <div class="products row" id="products-grid">
            <div v-for="(product, index) in products.products" :key="index" class="product col-md-3 col-sm-4" :data-title="product.title" :data-price="product.retailPrice" :data-all-groups="('brand' in product) ? groupsArray(product.brand.name, product.category) : ''">
              
              <div class="thumbnail">
                <div class="badges position-absolute m-2">
                  <img v-for="(badge, index) in product['badges']" :key="index" :src="badge" class="hidden-xs mb-1" />
                </div>
                <router-link class="product-url" :to="{name: 'product', params: {id: product.id}}" :title="product.title">
                  <img class="product-thumbnail w-100" :src="product.thumbnail" />
                </router-link>
                <div class="caption p-2">
                  <span v-if="'brand' in product" class="font-weight-bold">
                    <!-- <a :href="$router.resolve({name: 'product', params: {id: product.id}}).href">{{product.brand.name}}</a> -->
                    <router-link class="product-url" :to="{name: 'product', params: {id: product.id}}">{{product.brand.name}}</router-link>
                  </span>
                  <span v-else>-</span>
                  <h5>
                    <router-link class="product-url" :to="{name: 'product', params: {id: product.id}}" :title="product.title">{{product.title}}</router-link>
                  </h5>
                  <div v-if="product['retailPrice']" class="price">
                    {{t(getLang(), 'retail-price')}} <span class="currency"><span class="price-number">{{formatNumber(product['retailPrice'])}}</span> <span v-if="product['retailPriceIncludingVat']">{{t(getLang(), 'incl-vat')}}</span></span> &nbsp;
                  </div>
                  <div v-if="product['price']" class="product-price">
                    {{t(getLang(), 'your-price')}} <span class="currency-acronym">{{getAttribute('cur').toUpperCase()}}</span>
                    <span class="currency"><span class="product-price-number"> {{formatNumber(product['price'])}}</span></span>
                    <span v-if="product['priceQuantity'] != 0"> <span v-if="product['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span> {{t(getLang(), 'at')}} <span class="product-price-number-text">{{formatNumber(product['priceQuantity'], 0, 'quantity')}} {{t(getLang(), 'pcs')}}</span></span>
                    <span v-else><span v-if="product['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span></span>
                  </div>

                  <div class="keywords d-none">
                    <div class="description">{{product["description"]}}</div>
                    <div class="specifications">{{product.specifications}}</div>
                    <div v-if="'brand' in product" class="description">{{product['brand']['description']}}</div>
                  </div>
                </div>
              </div>            

            </div>
          </div>
          <br><br>
        </div> <!-- else end -->
        
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import NoOffer from "@/components/NoOffer";
import translations from '../plugins/translations';
import utils from '@/plugins/utils'
import $ from 'jquery';
import Shuffle from 'shufflejs';

function sortByTitle(element) {
  return element.getAttribute('data-title').toLowerCase();
}

function sortByPrice(element) {
  let price = element.getAttribute('data-price');
  if (!price) price = '0';
  return Number(price.replace(/[^0-9]+/g,""));
}

export default {
  props: ['products'],
  components: {
    NoOffer
  },  
  created() {
    this.loading = true;
    this.lang = window.APP_API_LANG;
    this.cur = window.APP_API_CUR;
    this.translations = translations;
  },
  mounted() {
    // this.getProducts(),
    // Wait for content to load after using router link.
    this.$nextTick(() => {
      this.initLoading();
      this.initShuffle();
    });
  },
  computed: {
    // ...mapState("product", ["products"])
  },
  beforeUpdate() {
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,

    // ...mapActions("product", ["getProducts"]),
    initLoading() {
      let endCheck = true;
      (async() => {
        while(!window.PRODUCTS_LOADED && endCheck) {
          // await new Promise(resolve => setTimeout(resolve, 100));
          await new Promise(function (resolve) {
            let counter = setTimeout(resolve, 1000);
            // Counter starts at 15.
            if (window.APP_NO_OFFER) endCheck = false;
            if (counter == 25) endCheck = false;
          });
        }
        $('.loading-spinner').remove();
        if (typeof window.APP_ERROR != 'undefined') {
          $('#error-msg').css('display', 'block');
          $('.other-prod-data-container').remove();
        }
        console.log(window.APP_ERROR);
      })();
    },

    getFilteredCategory(products) {
      if (typeof products != "undefined") {
        let elements = products
          .filter(product => product.category != "")
          .map(product => ({ 
            item: product.category
          }));
        let uniqueName = {};
        elements.forEach(element => {
          uniqueName[element.item] = element.item;
        });
        if (Object.keys(uniqueName).length == 0) {
          return false;
        }
        else {
          return Object.keys(uniqueName).sort();
        }
      }
    },

    getFilteredBrand(products, item) {
      if (typeof products != "undefined") {
        let elements = {};
        products.forEach(product => {
          if (typeof product.brand != "undefined") {
            elements[product.brand.name] = product.brand.name;
          }
        });
        if (Object.keys(elements).length == 0) {
          return false;
        }
        else {
          return Object.keys(elements).sort();
        }
      }
    },    
    
    // getFilteredBrand(products, item) {
    //   if (typeof products != "undefined" && 'brand' in products) {
    //     let elements = products
    //       .filter(product => product.brand.name != "")
    //       .map(product => ({ 
    //         item: product.brand.name
    //       }));
    //     let uniqueName = {};
    //     elements.forEach(element => {
    //       uniqueName[element.item] = element.item;
    //     });
    //     return Object.keys(uniqueName).sort();
    //   }
    // },

    sortThisClick(type) {
      let options = {};
      var sortType = type;
      if (sortType === 'title') {
        options = {
          by: sortByTitle,
        };
      } 
      else if (sortType === 'titleReversed') {
        options = {
          by: sortByTitle,
          reverse: true,
        };
      } 
      else if (sortType === 'price') {
        options = {
          by: sortByPrice,
        };
      } 
      else if (sortType === 'priceReversed') {
        options = {
          by: sortByPrice,
          reverse: true,
        };
      } 
      else {
        options = {};
      }
      window.productShuffle.sort(options);
    },

    filterThisClick(group) {
      window.productShuffle.filter(group);
    },

    groupsArray(brand, category) {
      return JSON.stringify(["alle", brand, category]);
    },

    initShuffle() {
      this.loading = false;
      $(function() {
        // Waiting for API...
        (async() => {
          while(!window.PRODUCTS_LOADED) {
            await new Promise(resolve => setTimeout(resolve, 10));
          }
          // Ready:
          var productGrid = $('#products-grid');
					// Using back button, breaks shuffle. If not in DOM, reload.
					if (Object.keys(productGrid).length == 0) {
						// location.reload();
					}
					else {
						window.productShuffle = new Shuffle(productGrid, {
							itemSelector: 'div.col-md-3.col-sm-4', // the selector for the items in the grid
						});
          }

          setTimeout(function filterOnHash() {
            let _hash = window.location.hash.substr(1);
            if (typeof _hash !== 'undefined') {
              _hash = decodeURIComponent(_hash);
              window.productShuffle.filter(_hash);
            }
          }, 1000);

          $('.product.col-md-3.col-sm-4').each(function() {
            $(this).attr('data-groups', JSON.stringify($(this).data('all-groups')));
          });

          // Clicking x in search field, resets product overview.
          $("#filters-search-input").on('search', function(e) {
            var searchText = e.target.value.toLowerCase();
            if (searchText === "") {
              window.productShuffle.filter();
            }
          });

          $("#filters-search-input").keyup(function(e) {
            var searchText = e.target.value.toLowerCase();
            window.productShuffle.filter(function (element, shuffle) {
              var caption = element.querySelector('.caption');
              var captionText = caption.textContent.toLowerCase().trim();
              return captionText.indexOf(searchText) !== -1;
            });
          });
        })();
      });
    }
  },

};
</script>

<style>
.product-container {
  min-height: 500px;
}
</style>