<template>
  <div id="app">
    <app-header :products="products" />
    <div class="main pt-2">
      <router-view :products="products"></router-view>
    </div>
    <app-footer :products="products" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";

export default {
  name: "app",
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState("product", ["products"])
  },
  mounted () {
    this.getProducts()
  },
  methods: {
    ...mapActions("product", ["getProducts"]),
    
  }
};
</script>
<style>
@import "../public/css/styles.css";
@import "../public/css/bootstrap-ie11.min.css";
</style>