import Home from "./pages/Home.vue";
import Overview from "./pages/Overview.vue";
import Product from "./pages/Product.vue";
import Offer from "./pages/Offer.vue";
import About from "./pages/About.vue";
import CSR from "./pages/CSR.vue";
import Contact from "./pages/Contact.vue";

export default [
    {
        path: '/:appid/',
        component: Home,
        name: 'home',
        props: true
    },
    {
        path: '/:appid/products',
        component: Overview,
        name: 'overview',
        props: true
    },
    {
        path: '/:appid/product/:id',
        component: Product,
        name: 'product',
        props: true
    },
    {
        path: '/:appid/offer',
        component: Offer,
        name: 'offer',
        props: true
    },
    {
        path: '/:appid/about',
        component: About,
        name: 'about'
    },
    {
        path: '/:appid/csr',
        component: CSR,
        name: 'csr'
    },
    {
        path: '/:appid/contact',
        component: Contact,
        name: 'contact'
    }
]