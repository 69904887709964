import Product from "../../../apis/Product";

function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
        return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
    window.local_storage_expiry = item.expiry;
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}
	return item.data;
}

function setWithExpiry(key, data, ttl) {
	const now = new Date();
	const item = {
		data: data,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item));
}

function allStorageValues() {
    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;
    while (i--) {
        values.push( localStorage.getItem(keys[i]) );
    }
    return values;
}

function allStorageKeys() {
    var keys = Object.keys(localStorage);
    return keys;
}

// Remove all other locally stored products from other offers.
const localStorageKeys = allStorageKeys();
const _keys = localStorage.length;
let i = 0;
for (i = 0; i < _keys; i++) {
    if (!localStorageKeys[i].includes(window.APP_API_CUSTOMER)) {
        localStorage.removeItem(localStorageKeys[i]);
    }
}

export const getProducts = ({ commit }, productId) => {
    let _products = getWithExpiry(window.APP_API_CUSTOMER);
    if (_products) {
        new Promise((resolve) => {
            resolve(_products);
        })
        .then(data => {
            // Stats start.
            console.log('Source: from local storage overview.');
            // Stats end.
            // let products = data.products.filter(product => product.id != productId);
            let languages = data.languages;
            let offerText = data.offerText;
            let contacts = data.contacts;
            let frontpageText = data.frontpageText;
            let theme = data.theme;
            let title = data.title;
            let responseCode = data.responseCode;
            let responseData = (data.responseCode == 403) ? data.responseData : 'received';
            let products = {};
            if ('products' in data) {
                products = data.products.filter(product => product.id != productId);
                products = {products, languages, offerText, contacts, frontpageText, theme, title, responseCode};
            }
            else {
                if (responseCode != 403) {
                    products = {products, languages, offerText, contacts, frontpageText, theme, title, responseCode};
                }
                else {
                    products = {responseCode, responseData};
                }
            }
            commit('SET_PRODUCTS', products);
        })
        .then(() => {
            window.PRODUCTS_LOADED = true;
        })
    }
    else {
        Product.all()
        .then(response => {
            console.log('Source: all products endpoint.');
            // let products = response.data.products.filter(product => product.id != productId);
            let products = response.data.products;
            let languages = response.data.languages;
            let offerText = response.data.offerText;
            let contacts = response.data.contacts;
            let frontpageText = response.data.frontpageText;
            let theme = response.data.theme;
            let responseCode = response.status;
            let responseData = (responseCode == 403) ? response.data.replace(/\r?\n|\r/g, '') : 'received';
            let title = response.data.title;
            window.APP_TITLE = title;
            products = {products, languages, offerText, contacts, frontpageText, theme, title, responseCode, responseData};
            setWithExpiry(window.APP_API_CUSTOMER, products, 2 * 60 * 1000);
            commit('SET_PRODUCTS', products);
            if (response.status == 403 || response.status == 404) {
                window.APP_API_ERROR = response.status;
                window.APP_RESPONSE = response.data.replace(/\r?\n|\r/g, '');
                commit('SET_STATUS', APP_RESPONSE);
            }
        })
        .then(() => {
            window.PRODUCTS_LOADED = true;
            commit('SET_STATUS', 'ok');
            console.log('Data requested.');
        })
        .catch(error => {
            window.APP_NO_OFFER = true;
            window.APP_ERROR = error;
            commit('SET_STATUS', 'error');
            // let responseCode = '404';
            // let products = {responseCode};
            // commit('SET_PRODUCTS', products);
            console.log('Error occurred.');
        })
    }
}

export const getProduct = ({ commit }, productId) => {
    let _product = getWithExpiry(window.APP_API_CUSTOMER + '_' + productId);
    // console.log({_product});
    if (_product) {
        new Promise((resolve) => {
            resolve(_product);
        })
        .then(data => {
            // Stats start.
            console.log('Source: from local storage product: ' + productId + '.');
            // Stats end.
            commit('SET_PRODUCT', data);
        })
        .then(() => {
            window.PRODUCT_LOADED = true;
        })
    }
    else {    
        Product.show(productId)
        .then(response => {
            console.log('Source: product endpoint.');
            setWithExpiry(window.APP_API_CUSTOMER + '_' + response.data.id, response.data, 2 * 60 * 1000);
            commit('SET_PRODUCT', response.data);
        })
        .then(() => {
            window.PRODUCT_LOADED = true;
        })
    }
}

export const getLabels = ({ commit }) => {
    let _labels = getWithExpiry(window.APP_API_CUSTOMER + '_labels');
    if (_labels) {
        new Promise((resolve) => {
            resolve(_labels);
        })
        .then(data => {
            // Stats start.
            console.log('Source: from local storage labels.');
            // Stats end.
            commit('SET_LABELS', data);
        })
        .then(() => {
            window.LABELS_LOADED = true;
        })
    }
    else {    
        Product.labels()
        .then(response => {
            console.log('Source: labels endpoint.');
            setWithExpiry(window.APP_API_CUSTOMER + '_labels', response.data, 60 * 60 * 1000);
            commit('SET_LABELS', response.data);
        })
        .then(() => {
            window.LABELS_LOADED = true;
        })
    }
}