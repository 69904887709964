<template>
  <div :class="products.theme" class="contact"> 
    <div class="container main-content"> 
      <div class="row fade-in-fast">
        <div class="col-sm-12">
          <h1>{{t(this.lang, 'contact')}}</h1>
          <p v-for="(contact, index) in products.contacts" :key="index">
            {{contact.fullname}}
            <br>
            {{contact.title}}
            <span v-if="contact.phone">
              <br>
              ✆ <a :href="`tel:${contact.phone}`">{{contact.phone}}</a>
            </span>
            <span v-if="contact.email">
              <br>
              ✉ <a :href="`mailto:${contact.email}`">{{contact.email}}</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from '@/plugins/translations';
import utils from '@/plugins/utils';
// import { mapState, mapActions } from "vuex";
export default {
  props: ['products'],
  data: function() {
    return {
    }
  },
  computed: {
    // ...mapState("product", ["products"]),
  },
  created() {
    this.lang = window.APP_API_LANG;
    this.translations = translations;
  },  
  mounted() {
    // this.getProducts(),
    this.setTitle();
    this.$nextTick(() => {
      this.setContainerHeight();
    })    
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,

    // ...mapActions("product", ["getProducts"]),

    setContainerHeight() {
      $(function() {
        let app = $('.main div:nth-child(1)');
        //  Get bottom y value for section.
        let appFromBottomPosition = app.offset().top + app.height();
        let appWindowHeight = $(window).height();
        console.log({appFromBottomPosition, appWindowHeight});
        // If section is completely visible, position it vertical middle by chaning the section height.
        if (appWindowHeight > appFromBottomPosition) {
          //Section is above the fold
          let availableSpace = appWindowHeight - appFromBottomPosition - 169;
          console.log({availableSpace});
          // Set height of content area to maximum height.
          app.css({
            // Make content area fill rest of page.
            'height': app.height() + availableSpace + 'px'
          });
        }
      });
    }
  }
};
</script>

<style>
.contact .container .row {
  min-height: 500px;
}
</style>