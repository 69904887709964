export default {
  'da': {
    'pageNotFoundHeader': 'Tilbuddet er ikke tilgængeligt',
    'pageNotFoundBody': 'Hvis du har spørgsmål, kontakt venligst <a href="mailto:support@metz.dk">Metz Support</a>.',
    'welcome-to': 'Velkommen hos',
    'xmas': 'Julen 2021',
    'frontpage': 'Forside',
    'categories': 'KATEGORIER',
    'meta-title': 'Metz produktoversigt',
    'search': 'Søg',
    'back': 'TILBAGE',
    'products': 'Produkter',
    'related-products': 'Relaterede produkter',
    'danish-crone': 'Danske Kroner',
    'currency': 'DKK',
    'currency-name': 'Valuta',
    'contact': 'Kontakt',
    'offer': 'Tilbud',
    'csr': 'Metz CSR politik',
    'about': 'Om Metz',
    'language': 'Sprog',
    'sort': 'SORTER',
    'all': 'Alle',
    'a-z': 'A-Å',
    'z-a': 'Å-A',
    'price-low-high': 'Pris - høj til lav',
    'price-high-low': 'Pris - lav til høj',
    'target-price': 'Vejl. pris',
    'your-price': 'Din pris',
    'incl-vat': 'inkl. moms',
    'ex-vat': 'ekskl. moms',
    'at': 'ved',
    'pcs': 'stk',
    'item-no': 'Varenr',
    'item-color': 'Farve',
    'item-size': 'Størrelse',
    'retail-price': 'Vejl. pris',
    'read-more': 'Læse mere',
    'description': 'Beskrivelse',
    'specifications': 'Specifikationer',
    'about-product': 'Om',
    'footer-difference': 'EN FORSKEL DU BLIVER HUSKET FOR',
    'no-product': 'Produktet blev ikke fundet.',
    'pages': {
      'about-p1': `<h1>Om Metz</h1>
      <h3>EN FORSKEL DER ER VÆRD AT HUSKE</h3>
      <p>
      Mere end 200 virksomheder stoler hvert år på, at Metz leverer høj kvalitet og trendsættende ideer. Det har naturligvis sine fordele at være branchens største og førende leverandør. Vi har de bedste leverandøraftaler og licenser. Og vi kan udvikle, designe og producere vores egne produkter i præcis den høje kvalitet, som Metz er kendt for. Selvfølgelig på bæredygtige fabrikker i Østen, som overholder vores stringente CSR politik.
      </p>`,
      'about-p2': `<p>
      Som den eneste danske virksomhed, har Metz opnået medlemskab af verdens største indkøbsorganisation, IGC Global Promotions, som er repræsenteret i 52 lande. Det betyder, at vi hvert år kan tilbyde et unikt sortiment af varer fra både egne kollektioner og fra store mærkevareproducenter. Det er den forskel, der adskiller os. Og det er vores garanti for, at reklamegaver fra Metz både er bæredygtige og værd at huske.</p>`,
      'about-p3': `<!--p>Læs mere om IGC Global Promotions her</p-->`,
      'csr-p1': `<h1>Metz CSR Politik</h1>
      <p>Som leverandør til Danmarks største virksomheder, ønsker Metz at være en ansvarlig og pålidelig samarbejdspartner. Metz har ingen produktion selv, men fungerer som indkøber for vores kunder. Størstedelen af Metz’ produktion produceres i henhold til kundernes ønsker og behov. Ved at levere de rette produkter, ved at yde høj service og ved at skabe transparens ned igennem værdikæden, ønsker vi at bidrage til at skabe tryghed og til at understøtte en vellykket forretning for vores kunder - det er de værdier, som Metz er grundlagt på.</p>`,
      'csr-p2': `<p>Vores CSR-politik tager udgangspunkt i, at vi ønsker at gøre en positiv forskel. Ansvarlig leverandørstyring og samarbejdet med leverandører er det område, hvor vi har vores største påvirkning og der, hvor vi kan gøre den største forskel i forhold til ansvarlighed. Rygraden i Metz CSR-indsats er de internationale principper i CSR-initiativet FN Global Compact, som Metz tilsluttede sig i 2013.</p>`,
      'csr-p3': `<p>Sammen med vores leverandører og med afsæt i FN Global Compact principperne, ønsker vi at arbejde for at skabe en mere ansvarlig leverandørkæde. Det gør vi ved proaktivt at påvirke og samarbejde med vores leverandører om at efterleve internationalt anerkendte principper for arbejdstager- og menneskerettigheder, miljø og anti-korruption.</p>`,
      'csr-p4': `<p>Læs vores communication on progress (COP) <a href="https://www.metz.dk/metz-cop-rapport-2019" target="_blank">her</a></p>`,
    }
  },
  'en': {
    'no-offer': 'No offer available.',
    'welcome-to': 'Welcome to',
    'xmas': 'Christmas 2021',
    'frontpage': 'Frontpage',
    'categories': 'CATEGORIES',
    'meta-title': 'Metz product overview',
    'search': 'Search',
    'back': 'BACK',
    'products': 'Products',
    'related-products': 'Related products',
    'danish-crone': 'Danish Krone',
    'currency': 'EUR',
    'currency-name': 'Currency',
    'contact': 'Contact',
    'offer': 'Offer',
    'csr': 'Metz CSR policy',
    'about': 'About Metz',
    'language': 'Language',
    'sort': 'SORT',
    'all': 'All',
    'a-z': 'A-Z',
    'z-a': 'Z-A',
    'price-low-high': 'Price - high to low',
    'price-high-low': 'Price - low to high',
    'your-price': 'Your price',
    'incl-vat': 'incl. VAT',
    'ex-vat': 'excl. VAT',
    'at': 'at',
    'pcs': 'pcs',
    'item-no': 'Item no.',
    'item-color': 'Color',
    'item-size': 'Size',
    'retail-price': 'Retail price',
    'read-more': 'Read more',
    'description': 'Description',
    'specifications': 'Specifications',
    'about-product': 'About',
    'footer-difference': 'GIFTS YOU WILL BE REMEMBERED FOR',
    'no-product': 'Product not found.',
    'pages': {
      'about-p1': `<h1>About Metz</h1>
      <h3>Gifts you will be remembered for</h3>
      <p>
      Every year, more than 200 companies trust Metz to deliver high quality products and trendsetting ideas. Naturally, it has its advantages being the leading and largest supplier in the Industry. We have the best supplier agreements and licenses. And we can develop, design and produce our own products in the high quality for which Metz is renowned. Of course, on sustainable factories in the far East, which comply which our strict CSR policy.
      </p>`,
      'about-p2': `<p>
      As the only Danish company, Metz has obtained membership of the world's largest procurement organisation, IGC Global Promotions, which is represented in 52 countries. This means that we are able to offer an unique range of products, both from our own collection and from our large brandname suppliers. This is what makes us different. And this is our guarantee, that company gifts from Metz are both sustainable and worth remembering.
      </p>`,
      'about-p3': `<!--p>Læs mere om IGC Global Promotions her</p-->`,
      'csr-p1': `<h1>Metz CSR Policy</h1>
      <p>
      Metz wishes to be a responsible and trustworthy business partner as a supplier of promotional products and corporate gifts to Denmark´s largest companies.
      Metz does not have any in-house production, but act as trend spotter and purchaser for our customers. By delivering the right products, by providing high quality service and by creating a transparent value chain, we want to support a successful business for our customers. Metz was founded upon these values and principles.
      </p>
      `,
      'csr-p2': `<p>
      We created our CSR policy with the goal of making a positive difference. We are committed to monitor and manage our supply chain in compliance with internationally recognized principles for Human Rights, Labour Standards, Environment and Anti- Corruption.
      </p>`,
      'csr-p3': `<p>
      The framework for, and backbone of, our CSR strategy and activities are based on the ten principles of the UN Global Compact that we joined in November 2013 as well as our environmental commitment.
      <br>
      Our commitment is integrated in our business values and communicated where we have business activities and follow as a minimum the law.
      </p>`,
      'csr-p4': `<p>
      Read our Communication on Progress 2019 (COP) <a href="https://www.metz.dk/metz-cop-rapport-2019" target="_blank">here</a>
      </p>`,
    }
  }
};