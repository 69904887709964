<template>
  <div class="container"> 
    <div class="row fade-in-fast">
      <div class="col-sm-7">
        <div v-html="p1"></div>
        <div v-html="p2"></div>
        <div v-html="p3"></div>
        <div v-html="p4"></div>
      </div>
      <div class="col-sm-5">
        <img class="p-4 mt-4 img-fluid" src="https://tilbud.metz.dk/root/0/B048FFB6F3CFA8DDC1257DC4004A7AB4/Body/0.7F0?OpenElement&FieldElemFormat=gif">
        <img class="p-4 img-fluid" src="https://tilbud.metz.dk/root/0/B048FFB6F3CFA8DDC1257DC4004A7AB4/Body/0.8632?OpenElement&FieldElemFormat=gif">
      </div>
    </div>
  </div>
</template>

<script>
import translations from '@/plugins/translations.js';
import utils from '@/plugins/utils';
export default {
  data: function() {
    return {
      'p1': translations[window.APP_API_LANG]['pages']['csr-p1'],
      'p2': translations[window.APP_API_LANG]['pages']['csr-p2'],
      'p3': translations[window.APP_API_LANG]['pages']['csr-p3'],
      'p4': translations[window.APP_API_LANG]['pages']['csr-p4']
    }
  },
  components: {
  },
  computed: {
  },
  created() {
  },  
  mounted() {
    this.setTitle();
  },
  mutations: {
  },
  methods: {
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,
  }
};
</script>

<style>
</style>