import Api from "./Api";
const END_POINT = '';
export default {
    all() {
        // Using module Api.js:
        return Api.get(`${END_POINT}?lang=${window.APP_API_LANG}&c=${window.APP_API_CUR}`)
    },
    show(id) {
        return Api.get(`${END_POINT}/product/${id}?lang=${window.APP_API_LANG}&c=${window.APP_API_CUR}`);
    }
}