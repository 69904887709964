<template>
  <div :class="products.theme"> 
    {{getSetTitleFromWindow()}}
    <div class="container">
      <no-offer />
      <div class="pt-3 mb-3"><a class="back" :href="$router.resolve({name: 'overview', query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href">{{t(getLang(), 'back')}}</a></div>
      <div v-if="product && checkObject(product) != 0">
        <div class="row mh700 prod-data-container">
          <div class="col-sm-6 mh700 prod-img-container">
            <div class="badges position-absolute m-2">
              <img v-for="(badge, index) in product['badges']" :key="index" :src="badge" class="hidden-xs mb-1" />
            </div>

            <div class="productGallery royalSlider rsCustom" style="display:none">
              <a class="rsImg" v-for="(imgs, index) in product.images" :key="index" :data-rsBigImg="imgs.Large" :href="imgs.Large">
                <img class="rsTmb" :src="imgs.small" />
              </a>
            </div>
          </div>
          <div class="col-sm-6 mt-2">
            <strong v-if="'brand' in product">
              <a :href="$router.resolve({name: 'overview', query: {'lang': this.lang, 'cur': this.cur}, hash: '#' + product.brand.name}).href">{{product.brand.name}}</a>
            </strong>
            <h3 class="mt-2">{{product.title}}</h3>
            <p>
              {{t(getLang(), 'item-no')}}.: {{product.productNumber}}
              <span v-if="product.color"><br>{{t(getLang(), 'item-color')}}: {{product.color}}</span>
              <span v-if="product.size"><br>{{t(getLang(), 'item-size')}}: {{product.size}}</span>
            </p>
            <div class="price">
              {{t(getLang(), 'retail-price')}} <span class="currency"><span class="price-number">{{formatNumber(product['retailPrice'])}}</span> <span v-if="product['retailPriceIncludingVat']">{{t(getLang(), 'incl-vat')}}</span></span> &nbsp;
            </div>
            <div class="product-price">
              {{t(getLang(), 'your-price')}} <span class="currency-acronym">{{getAttribute('cur').toUpperCase()}}</span>
              <span class="currency"><span class="product-price-number"> {{formatNumber(product['price'])}}</span></span>
              <span v-if="product['priceQuantity'] != 0"> <span v-if="products['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span> {{t(getLang(), 'at')}} <span class="product-price-number-text">{{formatNumber(product['priceQuantity'], 0, 'quantity')}} {{t(getLang(), 'pcs')}}</span></span>
              <span v-else><span v-if="product['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span></span>
            </div>
            <br />
            <img v-if="product['brand']" class="w-25 mb-4" :src="product['brand']['image']" />
            <div id="text-section" v-html="product.shortDescription"></div>
          </div>
        </div>
        <!-- Product accordion -->
        <div class="row mt-4 mx-auto">
          <accordion :product="product" />
        </div>        
      </div> <!-- end if product -->

      <div v-else class="mt700">
        <div v-if="productLoading" class="loading-spinner" style="margin-bottom:500px"><div></div><div></div><div></div><div></div></div>
        <div v-else class="fade-in-fast">
          <div class="fade-in-slowest d-flex justify-content-center">
            <div class="err-msg text-center text-light bg-error">
              {{t(getLang(), 'no-product')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <related-products :products="products" />
    
  </div>
</template>

<script>
import Accordion from "@/components/Accordion";
import NoOffer from "@/components/NoOffer";
import RelatedProducts from "@/components/RelatedProducts";
import $ from 'jquery';
import '@/assets/css/rs/royal-slider-custom.css';
import translations from '@/plugins/translations';
import utils from '@/plugins/utils'
import { mapState, mapActions } from "vuex";

export default {
  props: ["id", "products"],
  data: function() {
    return {
    }
  },
  components: {
    Accordion,
    NoOffer,
    RelatedProducts
  },
  computed: {
    // ...mapState("product", ["product", "products"]),
    ...mapState("product", ["product"]),
  },
  created() {
    this.loading = true;
    this.productLoading = true;
    let urlParams = new URLSearchParams(window.location.search);
    this.cur = urlParams.get('cur');
    this.lang = urlParams.get('lang');
    this.translations = translations;
  },  
  mounted() {
    this.getProduct(this.id),
    // this.getProducts(this.id),
    this.$nextTick(() => {
      this.loading = false;
      this.productLoading = false;
      this.initLoading();
      this.initGallery();
    })
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
    checkObject: utils.checkObject,
    getwindowWidth: utils.getWindowWidth,
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,

    getSetTitleFromWindow() {
      const metaTitle = document.querySelector('head title');
      if (typeof window.APP_TITLE != 'undefined') {
        metaTitle.textContent = window.APP_TITLE + ' - BAXX & METZ';
      }
      else {
        metaTitle.textContent = this.getTitle();
      }
    },

    getProductTitle() {
      return this.product.title;
    },

    // ...mapActions("product", ["getProduct", "getProducts"]),
    ...mapActions("product", ["getProduct"]),
    
    getImgSize(img, thisSize) {
      let _sizes = {'s':'400x400', 'm':'800x800', 'l':'1000x1000'};
      img = img.replace("_400x400", "_" + _sizes[thisSize]);
      return img;
    },

    getHighRes(thumbnails) {
      if (typeof thumbnails == 'undefined') return;
      let updatedThumbs = [];
      let _windowWidth = this.getwindowWidth();
      let _s = 0;
      if (_windowWidth >= 1000) {
        _s = "1000x1000";
      }
      else if (_windowWidth >= 800) {
        _s = "800x800";
      }
      else {
        _s = "400x400";
      }
      thumbnails.forEach(function(thumbnail) {
        updatedThumbs.push(thumbnail.replace("_400x400", "_" + _s));
      });
      return updatedThumbs;
    },

    initLoading() {
      let endCheck = true;
      (async() => {
        while(!window.PRODUCTS_LOADED && endCheck) {
          // await new Promise(resolve => setTimeout(resolve, 100));
          await new Promise(function (resolve) {
            let counter = setTimeout(resolve, 100);
            // Counter starts at 15.
            if (window.APP_NO_OFFER) endCheck = false;
            if (counter == 25) endCheck = false;
          });
        }
        $('.loading-spinner').remove();
        if (typeof window.APP_ERROR != 'undefined') {
          $('#error-msg').css('display', 'block');
          $('.other-prod-data-container').remove();
        }
        console.log(window.APP_ERROR);
      })();
    },    

    initGallery() {
      $(function() {

        (async() => {
          while(!window.PRODUCT_LOADED) {
            await new Promise(resolve => setTimeout(resolve, 10));
          }
          // Changing language or currency loads DOM late, so it breaks gallery.
          setTimeout(function () {
            var gallery = $('.productGallery');
            gallery.css('display','block');
            gallery.royalSlider({
              fullscreen: {
                enabled: true,
                nativeFS: false
              },
              controlNavigation: 'thumbnails',

              autoHeight: true,
              autoScaleSlider: true,
              
              imageScaleMode:'none',
              imageAlignCenter: false,

              navigateByClick: false, 

              keyboardNavEnabled: true,
              thumbs: {
                appendSpan: true,
                firstMargin: true,
                autoCenter: false,
                navigation: true
              }
            });
            var slider = gallery.data('royalSlider');
            console.log({slider});
            if (typeof slider === 'undefined') {
              window.location.reload();
            }
            slider.ev.on('rsSlideClick', function () {
              if (slider.isFullscreen) {
                slider.exitFullscreen();
              } else {
                slider.enterFullscreen();
              }
            });
            slider.ev.on('rsEnterFullscreen', function() {
              slider.st.imageScaleMode = 'fit';
              slider.updateSliderSize(true);
            });
            slider.ev.on('rsExitFullscreen', function() {
              slider.st.imageScaleMode = 'fill';
              slider.updateSliderSize(true);
            });
          }, 500);
          setTimeout(function () {
            $('.rsThumbsContainer').addClass('fade-in-slow').css('opacity','1');
          }, 1000);
        })();

      });
    }

  }
};
</script>

<style>
[v-cloak] {
  display: none;
}
.mh700 {min-height:700px}
.mh700 + .mt700 {margin-top:700px}
.btn-outline-primary.lb-modal-prev,
.btn-outline-primary.lb-modal-next {
  background-color: #007bff50;
}
.btn-outline-danger.lb-modal-close {
  background-color: #dc354550;
}
.back {
  padding-left: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1' viewBox='0 0 50 50' enable-background='new 0 0 50 50'%3E%3Cpath style='text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans' d='M 30.28125 8.28125 L 14.28125 24.28125 L 13.59375 25 L 14.28125 25.71875 L 30.28125 41.71875 L 31.71875 40.28125 L 16.4375 25 L 31.71875 9.71875 L 30.28125 8.28125 z' overflow='visible' enable-background='accumulate' font-family='Bitstream Vera Sans'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}
.h-250 {
  height: 15.75rem !important;
}

.thumbs {
  padding-left: 0;
}

.thumb {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 150ms ease-out;  
}

.thumb img {
  padding: 4px 6px 6px 4px;
}

.thumb:hover {
  box-shadow: 0px 0px 10px 0 #ddd;
  transform: scale(1.02);
}

.badges {
  z-index: 1;
}

.thumb,
.thumb img {
  height: 295px;
  width: 295px;
}

.thumbs li:nth-child(n+3),
.thumbs li:nth-child(n+3) img {
  height: 105px;
  width: 105px;
}
.prod-img-container {
 min-height:730px;
}
.rsThumbsContainer {
  opacity:0;
}

.rsSlide {
  height: 600px!important;
}

/* .rsSlide img {
  height: 100%;
} */

.container {
  background-color: #fff;
}

.xmas .container {
    background-color: #fff;
}

@media (max-width: 1399px) {
  .prod-img-container {
    min-height: 100%;
  }

  .rsCustom .rsThumbsHor {
      height: 50px;
  }
    
  .thumb,
  .thumb img {
    height: 250px;
    width: 250px;
  }

  .thumbs li:nth-child(n+3),
  .thumbs li:nth-child(n+3) img {
    height: 115px;
    width: 115px;
  }
}

@media (max-width: 1129px) {
  .thumb,
  .thumb img {
    height: 200px;
    width: 200px;
  }

  .thumbs li:nth-child(n+3),
  .thumbs li:nth-child(n+3) img {
    height: 105px;
    width: 105px;
  }
}

@media (min-width: 992px) {
  .h-lg-600 {
    height: 37.8rem !important;
  }
}

@media (max-width: 929px) {
  .thumb,
  .thumb img {
    height: 160px;
    width: 160px;
  }

  .thumbs li:nth-child(n+3),
  .thumbs li:nth-child(n+3) img {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 768px) {
  /* .h-md-400 {
    height: 25.2rem !important;
  } */

  .rsContainer, 
  .rsOverflow, 
  .rsVisibleNearbyWrap {
      height: 380px !important;
  }

  .mh700 {min-height:unset}
  .row.other-products {
    margin: 0;
  }
  h3 {
    margin-left: 15px;
  }

  .thumb,
  .thumb img {
    height: 365px;
    width: 365px;
  }

  .thumbs li:nth-child(n+2),
  .thumbs li:nth-child(n+2) img {
    height: 95px;
    width: 95px;
  }  
}
</style>