<template>
  <div v-if="products.products" class="container other-prod-data-container pb-4" v-cloak>
    <div class="row other-products fade-in-slower">
      <div class="row">
        <h3 class="mt-4 pl-3">{{t(getLang(), 'related-products')}}</h3>
      </div>
      <div v-for="(products, index) in products.products" :key="index" class="product col-md-3 col-sm-4">
        <div class="thumbnail">
          <div class="badges position-absolute m-2">
            <img v-for="(badge, index) in products['badges']" :key="index" :src="badge" class="hidden-xs mb-1" />
          </div>
          <a :href="$router.resolve({name: 'product', params: {id: products.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="products.title">
            <img class="product-thumbnail w-100" :src="products.thumbnail" />
          </a>
          <div class="caption p-2">
            <div v-if="'brand' in products" class="font-weight-bold">
              <a :href="$router.resolve({name: 'product', params: {id: products.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="products.title">{{products.brand.name}}</a>
            </div>
            <div v-else>{{'\xa0'}}</div>
            <h5>
              <a :href="$router.resolve({name: 'product', params: {id: products.id}, query: {'id': getAttribute('customer_id'), 'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="products.title">{{products.title}}</a>
            </h5>
            <div v-if="products['retailPrice']" class="price">
              {{t(getLang(), 'retail-price')}} <span class="currency"><span class="price-number">{{formatNumber(products['retailPrice'])}}</span> <span v-if="products['retailPriceIncludingVat']">{{t(getLang(), 'incl-vat')}}</span></span> &nbsp;
            </div>
            <div v-if="products['price']" class="product-price">
              {{t(getLang(), 'your-price')}} <span class="currency-acronym">{{getAttribute('cur').toUpperCase()}}</span>
              <span class="currency"><span class="product-price-number"> {{formatNumber(products['price'])}}</span></span>
              <span v-if="products['priceQuantity'] != 0"> <span v-if="products['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span> {{t(getLang(), 'at')}} <span class="product-price-number-text">{{formatNumber(products['priceQuantity'], 0, 'quantity')}} {{t(getLang(), 'pcs')}}</span></span>
              <span v-else><span v-if="products['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from '@/plugins/translations';
import utils from '@/plugins/utils'

export default {
  props: ["products"],
  data: function() {
    return {
    }
  },
  components: {
  },
  computed: {
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.cur = urlParams.get('cur');
    this.lang = urlParams.get('lang');
    this.translations = translations;
  },  
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
  }
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>