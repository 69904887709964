<template>
  <div :class="products.theme" class="offer"> 

    <div v-if="loading" class="loading-spinner"><div></div><div></div><div></div><div></div></div>
    <div v-else class="fade-in-fast">

      <div class="container">
        <div class="row" v-cloak>
          <div class="col-sm-12">
            <h1>{{t(this.lang, 'offer')}}</h1>
            <p v-html="products.offerText"></p>
          </div>
        </div> <!-- end if product -->
      </div>
    
    </div> <!-- end loading else -->
    
    <div class="container">
      <div class="row mx-auto pb-3">
        <h3 class="mt-4 pl-0">{{t(getLang(), 'products')}}</h3>
      </div>
      <div class="row other-products fade-in-slower">

        <div v-if="Object.keys(products).length == 0" class="ms-3 fade-in-slower" v-cloak>
          <div class="mx-auto text-light text-center err-msg bg-error">
            {{t(getLang(), 'no-offer')}}
          </div>
        </div>
        
        <div v-for="(products, index) in products.products" :key="index" class="product col-md-3 col-sm-4">
          <div class="thumbnail">
            <div class="badges position-absolute m-2">
              <img v-for="(badge, index) in products['badges']" :key="index" :src="badge" class="hidden-xs mb-1" />
            </div>
            <a :href="$router.resolve({name: 'product', params: {id: products.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="products.title">
              <img class="product-thumbnail w-100" :src="products.thumbnail" />
            </a>
            <div class="caption p-2">
              <span v-if="'brand' in products" class="font-weight-bold">
                <a :href="$router.resolve({name: 'product', params: {id: products.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="products.title">{{products.brand.name}}</a>
              </span>
              <h5>
                <a :href="$router.resolve({name: 'product', params: {id: products.id}, query: {'id': getAttribute('customer_id'), 'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="products.title">{{products.title}}</a>
              </h5>
              <div v-if="products['retailPrice']" class="price">
                {{t(getLang(), 'retail-price')}} <span class="currency"><span class="price-number">{{formatNumber(products['retailPrice'])}}</span> <span v-if="products['retailPriceIncludingVat']">{{t(getLang(), 'incl-vat')}}</span></span> &nbsp;
              </div>
              <div v-if="products['price']" class="product-price">
                {{t(getLang(), 'your-price')}} <span class="currency-acronym">{{getAttribute('cur').toUpperCase()}}</span>
                <span class="currency"><span class="product-price-number"> {{formatNumber(products['price'])}}</span></span>
                <span v-if="products['priceQuantity'] != 0"> <span v-if="products['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span> {{t(getLang(), 'at')}} <span class="product-price-number-text">{{formatNumber(products['priceQuantity'], 0, 'quantity')}} {{t(getLang(), 'pcs')}}</span></span>
                <span v-else><span v-if="products['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import translations from '../plugins/translations';
import utils from '@/plugins/utils'
export default {
  data: function() {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapState("product", ["products"]),
  },
  created() {
    this.loading = true;
    let urlParams = new URLSearchParams(window.location.search);
    this.cur = urlParams.get('cur');
    this.lang = urlParams.get('lang');
    this.translations = translations;

  },  
  mounted() {
    this.getProducts(),
    this.setTitle();
    this.$nextTick(() => {
      this.loading = false;
    })
  },
  mutations: {
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
    // Set meta title
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,

    ...mapActions("product", ["getProducts"])
  }
};
</script>

<style>
@media (max-width: 768px) {
  .row.other-products {
    margin: 0;
  }
  h3 {
    margin-left: 15px;
  }
}
[v-cloak] {
  display: none;
}
</style>