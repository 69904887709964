<template>
  <div class="row">
    <div v-for="(product, index) in products.products" :key="index" class="product col-md-3 col-sm-4">
      <div class="thumbnail">
        <div class="badges position-absolute m-2">
          <img v-for="(badge, index) in product['badges']" :key="index" :src="badge" class="hidden-xs mb-1" />
        </div>
        <a :href="$router.resolve({name: 'product', params: {id: product.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="product.title">
          <img class="product-thumbnail w-100" :src="product.thumbnail" />
        </a>
        <div class="caption p-2">
          <div v-if="'brand' in product" class="font-weight-bold">
            <a :href="$router.resolve({name: 'product', params: {id: product.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="product.title">{{product.brand.name}}</a>
          </div>
          <div v-else>{{'\xa0'}}</div>
          <h5>
            <a :href="$router.resolve({name: 'product', params: {id: product.id}, query: {'id': getAttribute('customer_id'), 'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="product.title">{{product.title}}</a>
          </h5>
          <div v-if="product.retailPrice" class="price">
            {{t(getLang(), 'retail-price')}} <span class="currency"><span class="price-number">{{formatNumber(product['retailPrice'])}}</span> <span v-if="product['retailPriceIncludingVat']">{{t(getLang(), 'incl-vat')}}</span></span>
          </div>
          <div v-if="product.price" class="product-price">
            {{t(getLang(), 'your-price')}} <span class="currency-acronym">{{getAttribute('cur').toUpperCase()}}</span>
            <span class="currency"><span class="product-price-number"> {{formatNumber(product['price'])}}</span></span>
            <span v-if="product['priceQuantity'] != 0"> <span v-if="product['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span> {{t(getLang(), 'at')}} <span class="product-price-number-text">{{formatNumber(products['priceQuantity'], 0, 'quantity')}} {{t(getLang(), 'pcs')}}</span></span>
            <span v-else><span v-if="product['priceExcludingVat']"> {{t(getLang(), 'ex-vat')}}</span></span>
          </div>
          <div v-else>{{'\xa0'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from '../plugins/translations';
import utils from '@/plugins/utils'

export default {
  props: ['products'],
  created() {
    this.loading = true;
    this.lang = window.APP_API_LANG;
    this.cur = window.APP_API_CUR;
    this.translations = translations;
  },
  mounted() {
  },
  computed: {
  },
  beforeUpdate() {
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
  },

};
</script>

<style>
</style>