<template>
  <div class="accordion p-0" id="accordion-parent">
    <div v-if="product['description']" class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          {{t(getLang(), 'description')}}
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion-parent">
        <div class="accordion-body">
          <div id="text-section" v-html="product['description']"></div>
        </div>
      </div>
    </div>
    <div v-if="product.specifications" class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          {{t(getLang(), 'specifications')}}
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion-parent">
        <div class="accordion-body">
          <div id="text-section" v-html="product.specifications"></div>
        </div>
      </div>
    </div>
    <div v-if="'brand' in product && product['brand']['description']" class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          {{t(getLang(), 'about-product')}} {{product.brand.name}}
        </button>
      </h2>
      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion-parent">
        <div class="accordion-body">
          <div id="text-section" v-html="product['brand']['description']"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from '../plugins/translations';
import utils from '@/plugins/utils'

export default {
  props: ['product'],
  created() {
    this.loading = true;
    this.lang = window.APP_API_LANG;
    this.cur = window.APP_API_CUR;
    this.translations = translations;
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
  },
};
</script>

<style>
</style>